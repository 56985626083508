
import { Avatar, Paper, Select, MenuItem, FormControl, InputLabel, TextField } from "@mui/material"
import { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap"
import axios from "axios";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import ChromeDinoGame from 'react-chrome-dino';
import { NumbersOutlined } from "@mui/icons-material";

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";


function Principale() {
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [siteId, setSiteId] = useState('');

    var config = {
        method: 'get',
        url: my_host + '/report?site_id=' + siteId,
        timeout: 10000000,
        withCredentials: true,
        useCredentials: true,
        data: {},
        headers: {
            'Content-Type': 'application/json'
        }
    }
    

    if (loading)
    {
        return (
            <ChromeDinoGame />
        )
    }
    

    return (
        <>
            <Container style={{ padding: '3%' }} fluid>
                <Row>
                    <Col>
                        <TextField label='site_id' onChange={(e) => {
                            setSiteId(e.target.value)
                        }}/>
                    </Col>
                    <Col>
                        <Button onClick={e => {
                            if (Number.isInteger(parseInt(siteId)))
                            {
                                setLoading(true);
                                axios(config)
                                .then(function (response) {
                                    console.log(response.data.report);
                                    setData(response.data.report)
                                }).then(e => {
                                    setLoading(false)
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                            }
                            
                        }}>
                            Generate !
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ReactMarkdown children={data} remarkPlugins={[remarkGfm]} style={{whiteSpace: 'pre-wrap'}}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Principale 