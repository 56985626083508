import { Row, Col } from 'react-bootstrap';
import './style.css';
import MenuIcon from '@mui/icons-material/Menu';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EngineeringIcon from '@mui/icons-material/Engineering';
import StorageIcon from '@mui/icons-material/Storage';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BugReportIcon from '@mui/icons-material/BugReport';
import HttpsIcon from '@mui/icons-material/Https';
import DD from '../../images/logo.png'
import { Link, Router, useNavigate } from "react-router-dom";
import { useState } from 'react';

let list_pages = [
    ['Overview', <AssessmentIcon />, '/'],
    ['Clients', <DashboardIcon />, '/Clients'],
    ['Monitoring', <HttpsIcon />, '/Monitoring'],
    ['Bug', <BugReportIcon />, '/Bug'],
    ['Date Checking', <EngineeringIcon/>, '/Date'],
    ['Data Quality', <StorageIcon/>, '/Quality'],
    ['Visu', <MenuIcon/>, '/Visualisation'],
    ['Theo Comp KPI', <MenuIcon/>, '/TheoLeGoat']


]


function Sidebar2(props) {
    console.log(window.location.pathname);

    let [path, setPath] = useState(undefined);
    return (
        <div className='sidebar' style={{ background: "#1E1E1E", color: "#ECECEC", fontFamily: 'Montserrat, sans-serif' }}>
            <Row style={{ padding: '10% 7% 7% 7%' }}>
                <Col style={{ margin: '1% 0 0 5%', }}>
                    <img src={DD} width={200} />
                </Col>
            </Row>
            {list_pages.map((e, i) => {
                return (
                    <Link style={{ textDecoration: 'none', color: 'white' }} to={e[2]} onClick={() => {setPath(e[2])}}>
                        {window.location.pathname == e[2] ?
                            <Row className='topbarover' style={{ marginLeft: '15%', fontSize: '1.3em', marginTop: '7%', padding: '5%', width: '70%', background: 'rgba(128, 126, 126, 0.452)' }}>
                                <Col style={{ marginLeft: '2%' }} sm={3}>
                                    {e[1]}
                                </Col>
                                <Col>
                                    {e[0]}
                                </Col>
                            </Row> :
                            <Row className='topbarover' style={{ marginLeft: '15%', fontSize: '1.3em', marginTop: '7%', padding: '5%', width: '70%' }}>
                                <Col style={{ marginLeft: '2%' }} sm={3}>
                                    {e[1]}
                                </Col>
                                <Col>
                                    {e[0]}
                                </Col>
                            </Row>

                        }

                    </Link>
                )
            })}

        </div>
    );
}

export default Sidebar2;
