import './App.css';
import Sidebar from './Component/Sidebar';
import Sidebar2 from './PageCOM/Components/Sidebar';
import Topbar from './Component/Topbar';
import Overview from './Pages/Overview';
import { Route, Routes, useLocation, useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import Login from './Pages/Login';
import axios from 'axios';
import { sha256 } from 'js-sha256';
import Cookies from 'universal-cookie';
import Connection from './Pages/Connection';
import Dashboard from './Pages/Dashboard';
import Api from './Pages/Api';
import Services from './Pages/Services';
import Test from './Pages/Test';
import Treatment from './Pages/Treatment';
import Monitoring from './Pages/Monitoring';
import Monitor from './PageCOM/Monitor';
import Principale from './PageCOM/Principle';
import Client from './PageCOM/Client';
import Konami from 'react-konami-code';
import Bug from './PageCOM/Bug';
import Volumetrie from './PageCOM/Volumetrie';
import Quality from './PageCOM/DataQuality';
import Visualisation from './PageCOM/Visualisation';
import Source from './PageCOM/Source';

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
  my_host = "http://localhost:3004";
  
const cookies = new Cookies();
const colors = {
  sidebar: '#1b1b1b',
  background: '#151515',
  text_color: 'white',
  topbar: '#151515',
  support_color: 'rgb(221, 71, 212)',
  support_color_trans: 'rgb(221, 71, 212, 0.2)'
}

const colors2 = {
  sidebar: 'black',
  background: 'white',
  text_color: 'black',
  topbar: 'white',
  support_color: 'black',
  support_color_trans: 'black'
}

const person = {
  nom: '',
  role: '',
  avatar: '',
  site_id: 0
}



function App() {
  let [selected, useSelected] = useState(0);
  let [auth, setAuth] = useState(undefined);
  let [type, setType] = useState(undefined);
  let [konami, setKonami] = useState(false);

  let connect = (username, password) => {
    axios(my_host + '/auth', { method: 'POST', data: { username: username, password: sha256(password) }, withCredentials: true, useCredentials: true }).then(e => {
      person.nom = e.data.identity.name + ' ' + e.data.identity.surname;
      person.role = e.data.identity.role;
      person.site_id = e.data.site_id;
      setType(e.data.identity.role);
      window.history.replaceState(null, "", "/");
      setAuth(e.data.Response);
    }).catch(e => {
      console.log('REMOVED', e);
      cookies.remove('dd_uuid_gestion');
      setAuth(undefined);
    })
  }

  const easterEgg = () => {
    console.log('KKKKOOOOO');
    alert('Infinite Ammo');
    setKonami(!konami);
  }

  useEffect(() => {

    axios(my_host + '/auth', { method: 'POST', data: { }, withCredentials: true, useCredentials: true }).then(e => {
      if (e.c != 'Already Connected')
      {
        
      }
    }).catch(e => {
      if (e.response.status)
      {
        console.log('Removing cookies');
        cookies.remove('dd_uuid_gestion');
        window.history.replaceState(null, "", "/");
        setAuth(undefined);
      }
    })

    if(cookies.get('dd_uuid_gestion')) {
      setAuth(true);
    }
  }, []);

  if (!auth)
  {
    return (
      
      <Routes>
        <Route path="/" element={<Login connect={connect}/>}/>
      </Routes>
        
    )
  }


  if (!konami && (1 || type === 'My CTO'))
  {
    console.log('HEEEEEEEEEEEEEEEY');
    return (
      <>
        <Sidebar2/>
        <Topbar colors={colors2} person={person}/>
        <Konami action={easterEgg}/>
        <div className="App" style={{ overflowX: 'hidden', overflowY: 'auto'}}>
          <Routes>
            <Route path="/" element={<Principale colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>}/>
            <Route path="/Clients" element={<Client colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>}/>
            <Route path="/Monitoring" element={<Monitor colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>}/>
            <Route path="/Bug" element={<Bug colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>}/>
            <Route path="/Date" element={<Volumetrie colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>}/>
            <Route path="/Quality" element={<Quality colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>}/>
            <Route path="/Visualisation" element={<Visualisation colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>}/>
            <Route path="/TheoLeGoat" element={<Source colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>}/>
          </Routes>
        </div>
      </>
    )
  }
  if (window.location.pathname === '/')
  {
    window.history.replaceState(null, "Overview", "/Overview");
  }

  return (
    <>

      <Sidebar colors={colors} selected={selected} handleChange={useSelected}/>
      <Topbar colors={colors} person={person}/>
      <div className="App" style={{background: colors.background, overflowX: 'hidden', overflowY: 'auto'}}>
      <Konami action={easterEgg}/>
      <Routes>
        <Route path="/" element={<Overview colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>} />
        <Route path="/Overview" element={<Overview colors={colors} cookie={cookies.get('dd_uuid_gestion')}/>} />
        <Route path="/Dashboard" element={<Dashboard colors={colors} person={person}/>} />
        <Route path="/Api" element={<Api colors={colors} />} />
        <Route path="/Treatment" element={<Treatment colors={colors} />} />
        <Route path="/Test" element={<Test colors={colors}/>} />
        <Route path="/Connections" element={<Connection colors={colors}/>} />
        <Route path="/Services" element={<Services colors={colors}/>} />
        <Route path="/ELK" element={<Monitoring colors={colors}/>} />
      </Routes>
      </div>
    </>
    
  );
}

export default App;
