import { Avatar, Paper, Select, MenuItem, FormControl, InputLabel, Input, TextField } from "@mui/material"
import Tooltip from '@mui/material/Tooltip';
import { Container, Row, Col } from "react-bootstrap"
import rick from '../images/rick-and.gif';
import axios from "axios";
import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import LinearProgress from '@mui/material/LinearProgress';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend
);

const options = {
    responsive: true,
    scales: {
        x: {
            grid: {
                color: 'white',
                borderColor: 'white',
            }
        },
        y: {
            ticks: {
                stepSize: 30
            },
            grid: {
                color: '#f3f3f3',
                borderColor: 'white',
            }
        },
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: '',
        }
    },

};

const generateData = (data) => {

    return {
        labels: data.map(e => { return e.date }),
        datasets: [
            {
                label: 'number of connection',
                fill: true,
                data: data.map(e => { return parseInt(e.count) }),
                borderColor: 'rgb(14, 57, 58)',
                backgroundColor: 'rgba(14, 57, 58, 0.5)',
            },
        ],
    }
};

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

var config = {
    method: 'get',
    url: my_host + '/overview',
    withCredentials: true,
    useCredentials: true,
    data: {},
    headers: {
        'Content-Type': 'application/json'
    }
}

function translateError(error) {
    if (!error) {
        return 'Undefined error';
    }
    let translate = [
        ['not found', "nous n'avons pas pu retraiter ce client, un fichier n'a pas été trouvé APPELEZ UN DEV LE CLIENT N'A PAS ETE RETRAITE"],
        ['email is required', "Pendant l'insertion en API un email a été omis RISQUE DE MANQUE DE DATA"],
        ['insertIntoAdUniqueScrapped', "l'insertion d'une annonce a échouée, RISQUE d'ERREUR DANS LES FILTRES ET LA VOLUMETRIE"],
        ["Erreur de récupération de l'historique", "Nous n'avons pas pu récuperer l'historique d'un candidat, il ne sera donc pas comptabilisé RISQUE DE MANQUE DE DATA"],
        ["insertIntoApiLogsCareer", "Erreur lors de l'insertion dans API LOGS CAREER, risque de donnée faussée pour les supports RISQUE DE MANQUE DE DATA"],
        ["Can't process candidates", "Nous n'avons pas pu processer un candidat RISQUE DE MANQUE DE DATA"],
        ["has no company_names listed in misc table", "Nous n'avons pas trouvé de company name, le client n'a pas pu être retraité"],
        ["Token", "Erreur de Token pour ce client un appel ATS est peut-etre necessaire"],
        ["Fatal error", "EUUUUUUH... APPELEZ UN DEV VITE !"],
        ['"email" is required', "Erreur d'insertion de candidat, email non renseigné"]
    ]

    for (const k in translate) {
        if (error.includes(translate[k][0])) {
            return translate[k][1]
        }
    }

    return 'Erreur non definis appelez un dev: ' + error;
}

function Principale() {

    const [dataOver, setDataOver] = useState(undefined);
    const [search, setSearch] = useState(undefined);

    useEffect(() => {
        axios(config)
            .then(function (response) {
                setDataOver(response.data.response)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])


    if (!dataOver) {
        return (
            <img src={rick} style={{position: 'absolute', top: '20vh', left: '55vh', zIndex: '10'}}/>
        )
    }


    return (
        <>
            <Container style={{ padding: '3%' }} fluid>
                <Paper style={{ border: '1px solid #F5F5F5', padding: '2%' }} elevation={0}>
                    <Row>
                        <Col style={{ fontWeight: '600' }}>
                            Erreurs Récentes
                        </Col>
                        <Col style={{ fontWeight: '600', textAlign: "right" }}>
                            <TextField id="outlined-basic" label="search" variant="outlined" onChange={(e) => {setSearch(e.target.value)}} />
                        </Col>
                    </Row>
                    <Row style={{ color: 'grey', padding: '1%', fontWeight: '300', marginTop: '1%', borderBottom: '1px solid #e9e9e9' }}>
                        <Col>
                            id
                        </Col>
                        <Col >
                            <div style={{ display: 'flex' }} >
                                <div style={{ flex: '1' }}>
                                    Date
                                </div>
                                <div style={{ flex: '1', fontWeight: '600', cursor: 'pointer' }} onClick={e => {
                                    let data = dataOver.bugs.bugs.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at) })
                                    console.log('==>', data);
                                    let over = JSON.parse(JSON.stringify(dataOver))
                                    over.bugs.bugs = data;
                                    setDataOver(over);
                                }}>
                                    ↓
                                </div>
                            </div>
                        </Col>
                        <Col sm={6}>
                            erreur
                        </Col>
                        <Col>
                            type
                        </Col>
                        <Col sm={2}>
                            <div style={{ display: 'flex' }} >
                                <div style={{ flex: '1' }}>
                                    filename
                                </div>
                                <div style={{ flex: '1', fontWeight: '600', cursor: 'pointer' }} onClick={e => {
                                    let data = dataOver.bugs.bugs.sort((a, b) => { return a.file.localeCompare(b.file) });
                                    console.log('sort');
                                    let over = JSON.parse(JSON.stringify(dataOver))
                                    over.bugs.bugs = data;
                                    setDataOver(over);
                                }}>
                                    ↓
                                </div>
                            </div>
                        </Col>
                        <Col >
                            <div style={{ display: 'flex' }} >
                                <div style={{ flex: '1' }}>
                                    site id
                                </div>
                                <div style={{ flex: '1', fontWeight: '600', cursor: 'pointer' }} onClick={e => {
                                    let data = dataOver.bugs.bugs.sort((a, b) => { return a.site_id - b.site_id })
                                    console.log(data);
                                    let over = JSON.parse(JSON.stringify(dataOver))
                                    over.bugs.bugs = data;
                                    setDataOver(over);
                                }}>
                                    ↓
                                </div>
                            </div>


                        </Col>
                        <Col >
                            <div style={{ display: 'flex' }} >
                                <div style={{ flex: '1' }}>
                                    name
                                </div>
                                <div style={{ flex: '1', fontWeight: '600', cursor: 'pointer' }} onClick={e => {
                                    let data = dataOver.bugs.bugs.sort((a, b) => { return a.name.localeCompare(b.name) })
                                    console.log(data);
                                    let over = JSON.parse(JSON.stringify(dataOver))
                                    over.bugs.bugs = data;
                                    setDataOver(over);
                                }}>
                                    ↓
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {dataOver.bugs.bugs.filter(e => {return !search || (search.length > 0 &&  (e.site_id == search || e.type.includes(search) ))}).map(e => {
                        return (
                            <Row style={{ padding: '1%', fontWeight: '600', textAlign: 'left', fontSize: '0.8em' }}>
                                <Col>
                                    {e.id}
                                </Col>
                                <Col >
                                    {e.created_at.replace('T', ' ').replace('Z', '').substring(0, 19)}
                                </Col>
                                <Tooltip title={e.error.message}>
                                    <Col sm={6}>
                                        {translateError(e.error.message)}
                                    </Col>
                                </Tooltip>
                                
                                <Col>
                                    {e.type}
                                </Col>
                                <Col sm={2}>
                                    {e.file}
                                </Col>
                                <Col>
                                    {e.site_id != undefined ? e.site_id : 'NULL'}
                                </Col>
                                <Col>
                                    {e.name != undefined ? e.name : 'Not fetched'}
                                </Col>
                            </Row>
                        )
                    })}
                </Paper>
            </Container>
        </>
    )
}

export default Principale 