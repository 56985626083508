import { Avatar, Paper, Select, MenuItem, FormControl, InputLabel, TextField, Tooltip } from "@mui/material"
import { Container, Row, Col } from "react-bootstrap"
import samsic from '../images/samsic.png';
import leroy from '../images/leroy.png';
import ga from '../images/ga.png';
import axios from "axios";
import { useEffect, useState } from "react";


export const data = {
    label: [],
    datasets: [
        {
            label: 'number of connection',
            fill: true,
            data: [],
            borderColor: 'rgb(14, 57, 58)',
            backgroundColor: 'rgba(14, 57, 58, 0.5)',
        },
    ],
};


var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

var config = {
    method: 'get',
    url: my_host + '/data-visu',
    withCredentials: true,
    useCredentials: true,
    data: {},
    headers: {
        'Content-Type': 'application/json'
    }
}

function Visualisation() {

    const [data, setData] = useState(undefined);
    const [sort, setSort] = useState(undefined);
    const [filter, setFilter] = useState(undefined);

    useEffect(() => {
        axios(config)
            .then(function (response) {
                setData(response.data.response);
                console.log(response.data.response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    if (!data) {
        return (
            <div>
                Loading la...
            </div>
        )
    }


    return (
        <>
            <Container style={{ padding: '3%' }} fluid>

                <Paper style={{ border: '1px solid #F5F5F5', padding: '2%' }} elevation={0}>
                    <Row>
                        <Col style={{ fontWeight: '600' }}>
                            Reattribution & rapprochement
                        </Col>
                        <Col style={{textAlign: 'right'}}>
                            <TextField label={'Company name'} onChange={(e) => {
                                setFilter(e.target.value)
                                
                            }} size='small' />
                        </Col>
                    </Row>
                    <Row style={{ color: 'grey', padding: '1%', fontWeight: '300', marginTop: '1%', borderBottom: '1px solid #e9e9e9' }}>
                        <Col onClick={() => {
                            data.contrats.sort((a, b) => {
                                return a.company_name.localeCompare(b.company_name)
                            })
                            setSort('company_name')
                            
                        }}>
                            company name
                        </Col>
                        <Col onClick={() => {
                            data.contrats.sort((a, b) => {
                                return a.site_id.localeCompare(b.site_id)
                            })
                            setSort('site_id')
                            
                        }}>
                            site id
                        </Col>
                        <Col onClick={() => {
                            data.contrats.sort((a, b) => {
                                return a.is_in_dev - b.is_in_dev
                            })
                            setSort('Staging')
                            
                        }}>
                            Staging
                        </Col>
                        <Col>
                            Reattribution Percent
                        </Col>
                        <Col onClick={() => {
                            data.contrats.sort((a, b) => {
                                let a_site = a.site_id;
                                let b_site = b.site_id;
                                let current_a = a.is_in_dev ? data.data_dev : data.data;
                                let current_b = b.is_in_dev ? data.data_dev : data.data;
                                let total_a = 0;
                                let total_b = 0;
                                try {
                                    Object.keys(current_a.rapprochable[a_site]).map(c => {
                                        total_a += parseInt(current_a.rapprochable[a_site][c], 10);
                                    })
                                    Object.keys(current_b.rapprochable[b_site]).map(c => {
                                        total_b += parseInt(current_b.rapprochable[b_site][c], 10);
                                    })
                                    let soloa = parseInt(current_a.rapprochable[a_site]['job_title empty and unmatching ad_id'], 10) / total_a;
                                    let solob = parseInt(current_b.rapprochable[b_site]['job_title empty and unmatching ad_id'], 10) / total_b;
                                    return soloa < solob ? 1 : (soloa == solob ?  0 : -1) 
                                } catch(e) {
                                    return 0
                                }
                                
                                

                            })
                            setSort('rappro')
                        }}>
                            Pourcentage de non rapprochement
                        </Col>
                        <Col>
                            Status
                        </Col>
                    </Row>
                
                {data.contrats.map(e => {
                    let site_id = e.site_id
                    let current = e.is_in_dev ? data.data_dev : data.data;

                    let percent = current.percent.find(it => {return it.site_id == site_id});
                    if (!current.rapprochable[site_id] || filter && filter.length > 0 && !e.company_name.toLowerCase().includes(filter.toLowerCase()))
                    {
                        return
                    }
                    let total = 0;
                    Object.keys(current.rapprochable[site_id]).map(a => {
                        total += parseInt(current.rapprochable[site_id][a], 10);
                        
                    })
                    let rappro = 0;
                    if (current.rapprochable[site_id] && current.rapprochable[site_id]['job_title empty and unmatching ad_id'] && total && total > 0)
                    {
                        rappro = parseInt(current.rapprochable[site_id]['job_title empty and unmatching ad_id']) / total * 100
                    }
                    return (
                        <Row style={{padding: '1%'}}>
                            <Col>
                                {e.company_name}
                            </Col>
                            <Col>
                                {e.site_id}
                            </Col>
                            <Col>
                                <div style={{padding: "3%", borderRadius: '10px', color: 'white', background: e.is_in_dev ? 'orange' : 'green', width: '70%'}}>
                                    {e.is_in_dev ? 'OnBoarding' : 'Prod'}
                                </div>
                                
                            </Col>
                            <Col>
                                {percent ? percent.count.toFixed(2) + ' %' : 'Non calculable'}
                            </Col>
                            <Col>
                                <Tooltip title={JSON.stringify(data.data.rapprochable[site_id])}>
                                    <div style={{padding: "3%", borderRadius: '10px', color: rappro > 50 ? 'white' : 'black' , background: 'rgba( 255, 0, 0, '+ rappro / 100 +' )', width: '70%'}}>
                                        {rappro ? rappro.toFixed(2) + ' %' : 'Sus'}
                                    </div>
                                </Tooltip>
                                
                                
                            </Col>
                            <Col>
                                {e.status}
                            </Col>
                        </Row>
                    )
                })}
                </Paper>
            </Container>
        </>
    )
}

export default Visualisation 