import { useEffect, useState } from "react";
import axios from 'axios';
import { Input, Paper, TextField } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import rick from '../images/rick-and.gif';

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

function Test(props) {
    const [response, setResponse] = useState(undefined);
    const [nameFilter, setNameFilter] = useState('');
    useEffect(e => {
        if (!response) {
            axios(my_host + '/test', { method: 'GET', data: {}, withCredentials: true, useCredentials: true }).then(e => {
                setResponse(e.data.response);
            });
        }
    })

    if (!response) {
        return (
            <img src={rick} style={{position: 'absolute', top: '20vh', left: '55vh', zIndex: '10'}}/>
        )
    }

    return (
        <div style={{ padding: '0 1% 0 1%' }}>
            <Row style={{  margin: '4% 0.5% 0 0.5%'}}>
                <Col>

                    <Row style={{marginBottom: '1%'}}>
                        <Col style={{fontSize: '1.7em', marginTop: '0%'}} sm={10}>
                            Date Checking
                        </Col>
                        <Col style={{marginTop: '0%'}}>
                            <TextField
                                onChange={(e) => { setNameFilter(e.target.value) }}
                                size='small'
                                id="outlined-basic"
                                label="search company name or number"
                                variant="outlined" />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{ borderTop: '1px solid #ECECF0', borderBottom: '1px solid #ECECF0', padding: '1% 0 1% 0', margin: '0 1% 0 1%', color: '#B1B1B1', position: 'sticky', background: 'white', top: '0vh'}}>
                <Col>
                    Nom de la company
                </Col>
                <Col>
                    Dernier Insertion API
                </Col>
                <Col>
                    Dernier Insertion Script
                </Col>
                <Col>
                    Dernier Support Treatment
                </Col>
                <Col>
                    Dernier Career Treatment
                </Col>
            </Row>

            {response.map(e => {
                if (nameFilter.length > 0 && !e.company_name.includes(nameFilter) && isNaN(parseInt(nameFilter))) {
                    return;
                }

                let num = parseInt(nameFilter);
                let mustReturn = false;
                if (nameFilter.length > 0 && !isNaN(num)) {
                    e.time.map(it => {
                        if (!it) {
                            return;
                        }
                        //console.log(it, Object.keys(it)[0]);
                        let label = Object.keys(it)[0];
                        let time = new Date(it[label])
                        let compare = (new Date() - time) / 24 / 60 / 60 / 1000;

                        if (it && Object.keys(it) && compare > num) {
                            console.log(compare, num);
                            mustReturn = true;
                        }
                    })
                }
                if (!mustReturn && !isNaN(num)) {
                    return;
                }


                return (
                    <Row style={{padding: '1% 0 1% 0', margin: '0 1% 0 1%', color: 'rgb(80 80 80)'}}>
                        <Col style={{borderRadius: '10px',  background: e.is_in_dev ? 'green' : 'orange', color: 'white', fontWeight: '600', textAlign: 'center'}}>
                            {e.company_name}
                        </Col>
                        {e.time.map(it => {
                            if (!it) {
                                return <Col>Undefined</Col>
                            }
                            let label = Object.keys(it)[0];
                            let time = new Date(it[label])
                            let compare = (new Date() - time) / 24 / 60 / 60 / 1000;
                            if (it && Object.keys(it)) {

                                return (
                                    <Col style={{ color: compare > 3 ? 'white' : 'black'}}>
                                        <div style={{background: compare > 3 ? '#fd000099' : 'white', width: '30%', borderRadius: '5px', padding: '0.7% 2% 0.7% 2%'}}>
                                            {compare.toFixed(0) + " days"}
                                        </div>
                                    </Col>
                                )


                            }
                        })}
                    </Row>
                )
            })}
        </div>
    )
}

export default Test;