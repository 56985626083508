import {Row, Col, Toast} from 'react-bootstrap'
import { Paper, TextField } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Cookies from 'universal-cookie';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Abonnement from './Components/Dashboard/Abonnement';
import Clients from './Components/Dashboard/Clients';
import Global from './Components/Dashboard/Global';
import './style.css';

import axios from 'axios';

const cookies = new Cookies();
var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

let text_color = undefined;
let support_color = undefined;

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: text_color,

    '&:hover': {
      color: support_color,
      opacity: 1,
      background: '#d0d0d02b'
    },
    '&.Mui-selected': {
      color: support_color,
      fontWeight: theme.typography.fontWeightMedium,
      
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));

  const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
    ))({
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            background: support_color,
          }
    });


function Dashboard(props) {
    const [value, setValue] = useState('0');
    const [site, setSite] =  useState(undefined);
    const [abo, setAbo] = useState(undefined);
    const [client, setClient] = useState(undefined);
    const [current, setCurrent] = useState(props.site_id);
    const [test, setTest] = useState(undefined);
    const [val, setVal] = useState(0);
    const [show, setShow] = useState(false);
    let handleChange = (e, v) => {
        setValue(v);
    }
    
    useEffect(() => {
        fetchData();
    }, [value, show]);

    const fetchData = async () => {
        let start = new Date();
        let end = new Date();
        start.setDate(start.getDate() - 30);

        let sites = axios(my_host + '/get_all_career', { method: 'GET', data: {}, withCredentials: true, useCredentials: true });
        let abos = axios(my_host + '/abonnements', { method: 'POST', data: {}, withCredentials: true, useCredentials: true });
        let tests = axios(my_host + '/compare', { method: 'POST', data: {start: start.toISOString().substring(0, 10), end: end.toISOString().substring(0, 10)}, withCredentials: true, useCredentials: true });
        let site_id = axios(my_host + '/auth', { method: 'POST', data: {}, withCredentials: true, useCredentials: true });
        
        await Promise.all([sites, abos, site_id, tests])
            .then(e => {
                console.log(e[3]);
                setSite(e[0].data.response);
                setAbo(e[1].data.response);
                setCurrent(e[2].data.site_id);
                let test = sortData(e[3].data.response)
                setTest(test);
            })
            .catch(e => {
                cookies.remove('dd_uuid_gestion');
                console.log('Error fetch data dashboard', e);
            });
    }

    const sortData = (data) => {
        for(const key of Object.keys(data)) {
            data[key] = data[key].sort(function (a, b) {
                console.log(a, b);
                if(parseInt(a.count, 10) > parseInt(b.count, 10)) {
                    return -1;
                } else if(parseInt(a.count, 10) < parseInt(b.count, 10)) {
                    return 1
                }
                return 0;
            })
        }

        return data;
    }

    if (site === undefined || abo === undefined || current === undefined || test === undefined)
    {
        return (<div>Loading ...</div>)
    }

    support_color = props.colors.support_color;
    text_color = props.colors.text_color;

    return (
        <div className="square">
            <Row style={{marginTop: '2%'}}>
                <Col  >
                    <Paper className='papier' style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '0'}} elevation={1}>
                        <StyledTabs
                            value={value} 
                            centered
                            onChange={handleChange} 
                            aria-label="basic tabs example"
                            scrollButtons="auto"
                        >
                            <AntTab label="Global" value="0" />
                            <AntTab label="Abonnement"  value="1" />
                            <AntTab label="Tous les clients" value="2"  />
                        </StyledTabs>
                    </Paper>
                    
                </Col>
            </Row>
            <Row style={{marginTop: '1%'}}>
                <Col sm={3}>
                    <Paper className='papier'  style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '5%'}}>
                        <Row>
                            <Col style={{fontWeight: '600', fontSize: '1.2em'}}>
                                Current site_id:
                            </Col>
                            <Col  style={{fontWeight: '600', fontSize: '1.2em', textAlign: 'right'}}>
                                { current}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{fontWeight: '600', fontSize: '0.9em', textAlign: 'right', color: props.colors.text_color}}>
                                {site.find(e => e.historical_id == current)['name']}
                            </Col>
                        </Row>
                        
                    </Paper>
                </Col>
                <Col>
                    <Paper className='papier'  style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '1.3%'}}>
                        <TextField
                            onChange={(e) => {setClient(e.target.value)}}
                            sx={{

                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    borderColor: props.colors.support_color
                                  },
                                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    borderColor: props.colors.support_color
                                  },
                                  "&:hover .MuiInputLabel-outlined": {
                                    color: props.colors.support_color
                                  },
                                  "& .MuiInputLabel-outlined": {
                                    color: props.colors.support_color
                                  },

                                label: {color: props.colors.text_color},
                                input : {color: props.colors.text_color},
                                
                                fieldset: {borderColor: props.colors.text_color, "border:focus": props.colors.text_color}
                                
                            }}  
                            fullWidth 
                            id="outlined-basic" 
                            label="Client name or id" 
                            variant="outlined" />
                        
                    </Paper>
                </Col>
            </Row>
            <Paper className='papier' style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '1% 5% 5% 5%', marginTop: '2%'}}>
                {[<Global data={test} colors={props.colors} setVal={setVal} val={val} />, <Abonnement abo={abo} colors={props.colors} career={site}/>, <Clients my_host={my_host} data={site} colors={props.colors} client={client} toast={setShow}/>][parseInt(value, 10)]}
            </Paper>
            <Toast style={{position: 'absolute', top: '80vh', left: '65vw'}} onClose={() => setShow(false)} show={show} delay={5000} autohide>
            <Toast.Header>
                <strong className="me-auto">Swap client</strong>
                <small>{current}</small>
            </Toast.Header>
            <Toast.Body>{'swaped to ' + site.find(e => e.historical_id == current)['name']}</Toast.Body>
            </Toast>
        </div>
    )
}

export default Dashboard;