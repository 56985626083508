import './style.css'
import {Row, Col} from 'react-bootstrap'
import { Paper } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import socketIOClient from "socket.io-client";
import axios from 'axios';
import React from 'react';
import Cookies from 'universal-cookie';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const cookies = new Cookies();
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
  },
};

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004"

const labels = ['15h', '16h', '17h', '18h', '19h', '20h', '21h'];

const data = (borderColor, backgroundColor, label, res, val) => {
    let map = {
        "0":"Temperature Server",
        "1":"CPU Usage",
        "2":"Ram Usage",
        "3":"CPU Sys Usage"
    }
    let map2 = {
        "0":"temperature",
        "1":"cpu",
        "2":"ram",
        "3":"cpu_sys"
    }
    //console.log(res.map(e => {   return e[map2[val]] }));
  return {
    labels: res.map(e => {return e.hour}),
    datasets: [
        {
        label: map[val],
        data: res.map(e => {   return e[map2[val]]    }),
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        fill: "origin"
        },
    ],
  }
  
};

//list.push({temperature: data['Temperature'] ? data['Temperature'] : 0, cpu: data['Cpu']['Current_load'], cpu_sys: data['Cpu']['Current_load_sys'], ram: data['Ram']['Used'] /  1000000000, hour: time})
let text_color = undefined;
let support_color = undefined

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: text_color,

    '&:hover': {
      color: support_color,
      opacity: 1,
      background: '#d0d0d02b'
    },
    '&.Mui-selected': {
      color: support_color,
      fontWeight: theme.typography.fontWeightMedium,
      
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));

let list = [];

function Overview(props) {
    let [value, setValue] = useState('0')
    const [response, setResponse] = useState(undefined);
    const [lock, setLock] = useState(false);
    let handleChange = (e, v) => {
        setValue(v);
    }

    useEffect(e => {
        const interval = setInterval(() => {
            axios(my_host + '/stats_server', { method: 'GET', data: {}, withCredentials: true, useCredentials: true }).then(e => {
                let data = e.data.response;
                list.push({temperature: data['Temperature'] ? data['Temperature'] : 0, cpu: data['Cpu']['Current_load'], cpu_sys: data['Cpu']['Current_load_sys'], ram: data['Ram']['Used'] /  1000000000, hour: new Date().toISOString().split('T')[1].substring(0, 8)})
                if (list.length > 20)
                {
                    list.splice(0, 1);
                }
                setResponse(data)
            }).catch(e => {
                cookies.remove('dd_uuid_gestion');
                window.history.replaceState(null, "", "/");
            })
          }, 1000)
          return () => clearInterval(interval)
    })
    

    support_color = props.colors.support_color;
    text_color = props.colors.text_color;

    const StyledTabs = styled((props) => (
        <Tabs
            {...props}
            TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        />
        ))({
            '& .MuiTabs-indicator': {
                display: 'flex',
                justifyContent: 'center',
                background: support_color,
              }
        });

    if (!response)
    {
        return (<div>Loading</div>)
    }
    
    return (
        <div className="square">
            <Row>
                <Col>
                    <Paper className='papier' elevation={1} style={{borderRight: '5px solid red', background: props.colors.sidebar, color: props.colors.text_color}}>
                        <Row style={{marginBottom: '10%'}}>
                            <Col className='title'>
                                Temperature Serveur
                            </Col>
                        </Row>
                        <Row>
                            <Col className='num'>
                                {response['Temperature'] === null ? 'N/A' : response['Temperature']}
                            </Col>
                        </Row>
                       
                    </Paper>
                </Col>
                <Col>
                    <Paper className='papier' elevation={1} style={{borderRight: '5px solid yellow', background: props.colors.sidebar, color: props.colors.text_color}}>
                        <Row style={{marginBottom: '10%'}}>
                            <Col className='title'>
                                CPU Usage
                            </Col>
                        </Row>
                        <Row>
                            <Col className='num'>
                                {response['Cpu']['Current_load'].toFixed(2) + ' %'}
                            </Col>
                        </Row>
                       
                    </Paper>
                </Col>
                <Col>
                    <Paper className='papier' elevation={1} style={{borderRight: '5px solid #5aff5a', background: props.colors.sidebar, color: props.colors.text_color}}>
                        <Row style={{marginBottom: '10%'}}>
                            <Col className='title'>
                                RAM Usage
                            </Col>
                        </Row>
                        <Row>
                            <Col className='num'>
                                {(response['Ram']['Used'] / 1000000000).toFixed(2) + 'Gb / ' + (response['Ram']['Total'] / 1000000000).toFixed(2) + ' Gb'}
                            </Col>
                        </Row>
                       
                    </Paper>
                </Col>
                <Col>
                    <Paper className='papier' elevation={1} style={{borderRight: '5px solid pink', background: props.colors.sidebar, color: props.colors.text_color}}>
                        <Row style={{marginBottom: '10%'}}>
                            <Col className='title'>
                                CPU Sys Usage
                            </Col>
                        </Row>
                        <Row>
                            <Col className='num'>
                                {response['Cpu']['Current_load_sys'].toFixed(2) + ' %'}
                            </Col>
                        </Row>
                       
                    </Paper>
                </Col>
            </Row>
            <Row style={{marginTop: '2%'}}>
                <Col md={{span: 5, offset: 3}}>
                    <Paper className='papier' style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '0'}} elevation={1}>
                        <StyledTabs
                            centered
                            value={value} 
                            onChange={handleChange} 
                            aria-label="basic tabs example"
                            scrollButtons="auto"
                        >
                            <AntTab label="Temperature Server" value="0" />
                            <AntTab label="CPU Usage"  value="1" />
                            <AntTab label="Ram Usage" value="2"  />
                            <AntTab label="CPU Usage Sys" value="3"  />
                        </StyledTabs>
                    </Paper>
                    
                </Col>
                
            </Row>
            <Row style={{marginTop: '2%'}}>
                <Col>
                    <Paper className='papier' style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '5%'}} elevation={1}>
                        <Line options={options} data={data(props.colors.support_color, props.colors.support_color_trans , 'Prod', list, value)} />
                    </Paper>
                </Col>
                <Col>
                    <Paper className='papier' style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '5%'}} elevation={1}>
                        <Line options={options} data={data(props.colors.support_color, props.colors.support_color_trans , 'Prod', list, value)} />
                    </Paper>
                </Col>
            </Row>
        </div>
    )
}

export default Overview;