import bg from '../images/falls.jpg'
import logo from '../images/logo.png'
import logo2 from '../images/logo2.png'
import TextField from '@mui/material/TextField';
import {Row, Col} from 'react-bootstrap'
import { useState } from 'react';


function Login(props) {
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');
  return (
    <div className='log' style={{top: 0, left: 0, position: 'absolute', width: '100vw', height: '100vh'}}>
        <div style={{position: 'absolute', width: '40vw', height: '100vh', background: '#fff', color: 'black', top: '0', left: '0', padding: '2% 10% 1% 10%'}}>
            <Row style={{marginBottom: '10%'}}>
                <Col style={{marginLeft: '-42%', marginTop: '-5%'}}>
                    <img src={logo2} width="30%"/>
                </Col>
            </Row>
            <Row>
                <Col style={{fontSize: '1.6em', fontWeight: '600', textAlign: 'left'}}>
                    Log In
                </Col>
            </Row>
            <Row>
                <Col style={{fontSize: '0.7em', textAlign: 'left', color: 'grey'}}>
                    access the data-driven-management
                </Col>
            </Row>
            <Row style={{marginTop: '15%'}}>
                <Col>
                    <div style={{borderRadius: '0', cursor: 'pointer', background: 'black', color: 'white', padding: '7%', textAlign: 'center', fontSize: '1.2em', fontWeight: '600'}}>
                        Connect via SSO
                    </div>
                </Col>
                <Col>
                    <div style={{borderRadius: '0', cursor: 'pointer', background: '#488fb5', color: 'white', padding: '7%', textAlign: 'center', fontSize: '1.2em', fontWeight: '600'}}>
                        Connect via DOM
                    </div>
                </Col>
            </Row>

            <Row style={{marginTop: '30%', marginBottom: '15%'}}>
                <Col>
                    <TextField type={"email"} fullWidth id="emailnew" label="Email" variant="standard" onChange={e => {
                        setEmail(e.target.value)
                    }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextField type={"password"} fullWidth  id="mdp" label="Password" variant="standard" onChange={e => {
                        setPassword(e.target.value)
                    }}/>
                </Col>
            </Row>
            <Row style={{marginTop: '30%'}}>
                <Col>
                    <div style={{borderRadius: '0', cursor: 'pointer', background: '#488fb5', color: 'white', padding: '5%', textAlign: 'center', fontSize: '1.2em', fontWeight: '600'}} onClick={() => {
                        props.connect(email, password)

                    }}>
                        Connect
                    </div>
                </Col>
            </Row>
        </div>
        <div style={{position: 'absolute', width: '5vw', height: '5vh', top: '90vh', left: '90vw'}}>
            <img src={logo} style={{width: '160%', height: '100%'}}/>
        </div>
    </div>
    
  );
}

export default Login;
