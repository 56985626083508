import { useState } from "react";
import axios from 'axios';
import {Row, Col} from 'react-bootstrap'
import { Paper } from "@mui/material";

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

let services_list = [
    {
        name: 'Portainer',
        site_url: 'https://portainer.data-driven.fr'
    },
    {
        name: 'Server Control Cockpit',
        site_url: 'https://162.19.59.195:9090'
    },
    {
        name: 'DEV Dashboard',
        site_url: 'https://dev-dashboard.data-driven.fr'
    },
    {
        name: 'Dashboard',
        site_url: 'https://dashboard.data-driven.fr'
    }
]

function Services(props)
{   
    return (
        <div className="square">
            <Row>
                {services_list.map(e => {
                    return (
                        <Col>
                            <Paper className='papier' style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '1%'}} elevation={1}>
                                <Row style={{textAlign: 'left', fontSize: '1.2em', fontWeight: '600'}}>
                                    <Col>
                                        {e.name}
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col style={{textAlign: 'center'}}>
                                        <a style={{textDecoration: 'none', color: props.colors.text_color}} href={e.site_url}>{e.site_url}</a>
                                    </Col>
                                    
                                </Row>
                            </Paper>
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

export default Services;