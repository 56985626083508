import { useEffect, useState } from "react";
import axios from 'axios';
import { Input, Paper, TextField } from "@mui/material";
import { Row, Col} from "react-bootstrap";

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

function Test(props)
{
    const [response, setResponse] = useState(undefined);
    const [nameFilter, setNameFilter] = useState('');
    useEffect(e => {
        if (!response)
        {
            axios(my_host + '/test', { method: 'GET', data: {}, withCredentials: true, useCredentials: true }).then(e => {
                console.log(e.data.response);
                setResponse(e.data.response);
            });
        }
    })

    if (!response)
    {
        return <div>LOADING...</div>
    }

    return (
        <div style={{color: props.colors.text_color}}>
            <Row>
                <Col>
                    <Paper className='papier' elevation={1}  style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '1%', margin: '2%'}}>
                    <TextField
                            onChange={(e) => {setNameFilter(e.target.value)}}
                            sx={{

                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    borderColor: props.colors.support_color
                                  },
                                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    borderColor: props.colors.support_color
                                  },
                                  "&:hover .MuiInputLabel-outlined": {
                                    color: props.colors.support_color
                                  },
                                  "& .MuiInputLabel-outlined": {
                                    color: props.colors.support_color
                                  },

                                label: {color: props.colors.text_color},
                                input : {color: props.colors.text_color},
                                
                                fieldset: {borderColor: props.colors.text_color, "border:focus": props.colors.text_color}
                                
                            }}  
                            fullWidth 
                            id="outlined-basic" 
                            label="Client name or number of days" 
                            variant="outlined" />
                    </Paper>
                    
                </Col>
            </Row>
            <Paper className='papier' elevation={1} style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '1%', margin: '2%'}}>
                <Row>
                    <Col>
                        company name
                    </Col>
                    <Col>
                        last api_logs_career insert
                    </Col>
                    <Col>
                        last tags_logs_career insert
                    </Col>
                    <Col>
                        last kpi_support Treatment
                    </Col>
                    <Col>
                        last kpi_career Treatment
                    </Col>
                </Row>
                
            </Paper>
            {response.map(e => {
                if (nameFilter.length > 0 && !e.company_name.includes(nameFilter) && isNaN(parseInt(nameFilter)))
                {
                    return;
                }
                
                let num = parseInt(nameFilter);
                let mustReturn = false;
                if (nameFilter.length > 0 && !isNaN(num))
                {
                    e.time.map(it => {
                        if (!it)
                        {
                           return; 
                        }
                        //console.log(it, Object.keys(it)[0]);
                        let label = Object.keys(it)[0];
                        let time = new Date(it[label])
                        let compare = (new Date() - time) / 24 / 60 / 60 / 1000;
                        
                        if (it && Object.keys(it) && compare > num)
                        {
                            console.log(compare, num);
                            mustReturn = true;
                        }
                    })
                }
                if (!mustReturn && !isNaN(num))
                {
                    return;
                }
                

                return (
                    <Paper className='papier' elevation={1} style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '1%', margin: '2%'}}>
                        <Row>
                            <Col>
                                {e.company_name}
                            </Col>
                            {e.time.map(it => {
                                if (!it)
                                {
                                    return <Col>Undefined</Col>
                                }
                                let label = Object.keys(it)[0];
                                let time = new Date(it[label])
                                let compare = (new Date() - time) / 24 / 60 / 60 /1000;
                                if (it && Object.keys(it))
                                {
                                    
                                    return (
                                        <Col style={{color: compare > 3 ? 'red' : 'white'}}>
                                            {compare.toFixed(0) + " days"}
                                        </Col>
                                    )
                                    
                                    
                                }
                            })}
                        </Row>
                    </Paper>
                )
            })}
        </div>
    )
}

export default Test;