import { Row, Col } from 'react-bootstrap';
import './style.css';
import HelpIcon from '@mui/icons-material/Help';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { green, pink } from '@mui/material/colors';
import { Avatar } from '@mui/material';



function Topbar(props) {
  return (
    <div className='topbar' style={{background: props.colors.topbar, color: props.colors.text_color}}>
        <Row>
            <Col style={{fontSize: '1.5em'}} sm={10}>
                {'Bonjour ' + props.person.nom}
            </Col>
            <Col>

                
                <Row style={{color: props.colors.text_color}}>
                    <Col sm={2}>
                        <HelpIcon  className='topbarover' style={{marginRight: "10%"}}/>
                    </Col>
                    <Col sm={2}>
                        <NotificationsIcon className='topbarover'/>
                    </Col>
                    <Col sm={3}>
                        <Avatar className='topbarover' src={props.person.avatar} sx={{ width: 30, height: 30, bgcolor: pink[500]}}/>
                    </Col>
                    <Col className='topbarover' style={{marginTop: '-3%', borderRadius: '5px'}}>
                        <Row style={{fontWeight: '600', fontSize: '0.9em'}}>
                            {props.person.nom}
                        </Row>
                        <Row style={{fontSize: '0.6em'}}>
                            {props.person.role}
                        </Row>
                    </Col>
                
                
                
                </Row>
                
            </Col>
        </Row>
    </div>
  );
}

export default Topbar;
