import { Avatar, Paper, Select, MenuItem, FormControl, InputLabel } from "@mui/material"
import { Container, Row, Col } from "react-bootstrap"
import rick from '../images/rick-and.gif';
import axios from "axios";
import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import LinearProgress from '@mui/material/LinearProgress';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    scales: {
        x: {
            grid: {
                color: 'white',
                borderColor: 'white',
            }
        },
        y: {
            ticks: {
                stepSize: 30
            },
            grid: {
                color: '#f3f3f3',
                borderColor: 'white',
            }
        },
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: '',
        }
    },

};

const generateData = (data) => {

    return {
        labels: data.map(e => { return e.date }),
        datasets: [
            {
                label: 'number of connection',
                fill: true,
                data: data.map(e => { return parseInt(e.count) }),
                borderColor: 'rgb(14, 57, 58)',
                backgroundColor: 'rgba(14, 57, 58, 0.5)',
            },
        ],
    }
};

const labels = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

export const data = {
    labels,
    datasets: [
        {
            label: 'number of connection',
            fill: true,
            data: [10, 30, 59, 27, 15, 5, 2],
            borderColor: 'rgb(14, 57, 58)',
            backgroundColor: 'rgba(14, 57, 58, 0.5)',
        },
    ],
};

let fake_client = [
    { id: 1, email: 'truc@samsic.fr', date: '2023-01-01', client: 'samsic', status: 'onBoarding' },
    { id: 1, email: 'truc@samsic.fr', date: '2023-01-01', client: 'samsic', status: 'dev' },
    { id: 1, email: 'truc@samsic.fr', date: '2023-01-01', client: 'samsic', status: 'prod' }
]

function carte(label, nb, date, negativ, comment) {
    return (
        <Paper style={{ borderRadius: '10px', padding: '4%', background: negativ ? '#0E393A' : '#f3f3f3', color: negativ ? 'white' : 'black' }} elevation={0}>
            <Row>
                <Col sm={8} style={{ fontSize: '1.0em', color: negativ ? 'white' : 'black', fontWeight: '600' }}>
                    {label}
                </Col>
                <Col style={{ fontSize: '0.7em', color: 'grey', fontWeight: '300', textAlign: 'right' }}>
                    {new Date().toDateString()}
                </Col>
            </Row>
            <Row style={{ marginTop: '3%' }}>
                <Col style={{ fontSize: '1.9em', fontWeight: '600', textAlign: 'center' }}>
                    {nb}
                </Col>
            </Row>
            <Row>
                <Col style={{ fontSize: '0.8em', fontWeight: '300', color: 'grey' }}>
                    {comment}
                </Col>
            </Row>
        </Paper>
    )
}

function stageStyle(stage, text) {
    if (stage == 'dev') {
        return (
            <Col style={{ background: 'rgba(14, 57, 58, 0.5)', color: 'rgba(14, 57, 58)' }}>
                {text}
            </Col>
        )
    }
    if (stage == 'onBoarding') {
        return (
            <Col style={{ background: 'rgba(239, 131, 81, 0.5)', color: 'rgba(14, 57, 58)' }}>
                {text}
            </Col>
        )
    }
    if (stage == 'prod') {
        return (
            <Col style={{ background: 'rgba(14, 57, 58, 0.5)', color: 'rgb(14, 57, 58)' }}>
                {text}
            </Col>
        )
    }
    return (
        <Col style={{ background: 'rgba(14, 57, 58, 0.5)', color: 'rgb(14, 57, 58)' }}>
            {text}
        </Col>
    )

}

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

var config = {
    method: 'get',
    url: my_host + '/overview',
    withCredentials: true,
    useCredentials: true,
    data: {},
    headers: {
        'Content-Type': 'application/json'
    }
}

function Principale() {

    const [dataOver, setDataOver] = useState(undefined)

    useEffect(() => {
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setDataOver(response.data.response)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])


    if (!dataOver) {
        return (
            <img src={rick} style={{ position: 'absolute', top: '20vh', left: '55vh', zIndex: '10' }} />
        )
    }

    return (
        <>
            <Container style={{ padding: '3%' }} fluid>
                <Row style={{ marginTop: '2%' }}>
                    <Col >
                        <Paper style={{ border: '1px solid #F5F5F5', padding: '3%' }} elevation={0}>
                            <Row>
                                <Col style={{ fontWeight: '600', fontSize: '1.2em' }}>
                                    Clients se connectant le plus
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Bar options={options} data={generateData(dataOver.connection.connection_by_person)} width={"100%"} height={"30%"} />
                                </Col>
                            </Row>

                        </Paper>
                    </Col>
                </Row>
                <Row style={{ marginTop: '2%' }}>
                    <Col >
                        <Paper style={{ border: '1px solid #F5F5F5', padding: '2%', maxHeight: '20vh' }} elevation={0}>
                            <Row>
                                <Col style={{ fontWeight: '600' }}>
                                    Connexion récente
                                </Col>
                            </Row>
                            <Row style={{ color: 'grey', padding: '1%', fontWeight: '300', marginTop: '1%', borderBottom: '1px solid #e9e9e9' }}>
                                <Col>
                                    id
                                </Col>
                                <Col sm={4}>
                                    email
                                </Col>
                                <Col>
                                    Date
                                </Col>
                                <Col>
                                    client
                                </Col>
                                <Col>
                                    status
                                </Col>
                            </Row>
                            {dataOver.connection.connection_generic.map(e => {
                                return (
                                    <Row style={{ padding: '1%', fontWeight: '600', textAlign: 'left', fontSize: '0.7em' }}>
                                        <Col>
                                            {e.id}
                                        </Col>
                                        <Col sm={4}>
                                            {e.email}
                                        </Col>
                                        <Col>
                                            {e.created_at.substring(0, 19).replace('T', ' ')}
                                        </Col>
                                        <Col>
                                            {'to_add'}
                                        </Col>
                                        <Col>
                                            {'Status not defined'}
                                        </Col>
                                        {/*stageStyle(e.status, e.status)*/}
                                    </Row>
                                )
                            })}
                        </Paper>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Principale 