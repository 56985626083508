import axios from 'axios';
import {Row, Col, Button} from 'react-bootstrap';

const Clients = (props) => {
    let map = props.data.filter((e => {return props.client == undefined || (e.historical_id.includes(props.client) || e.name.toLowerCase().includes(props.client.toLowerCase()))})).map(e => {
        return (
            <Row style={{color: props.colors.text_color, padding: '2%', borderBottom: '1px solid grey'}}>
                <Col>
                    {e['name']}
                </Col>
                <Col>
                    {e['historical_id']}
                </Col>
                <Col>
                    <Button style={{background: props.colors.support_color}} variant="contained" onClick={() => {
                        axios(props.my_host + '/switch_acount', { method: 'POST', data: {id: e['historical_id']}, withCredentials: true, useCredentials: true }).then(e => {
                            console.log('SWAPED');
                            props.toast(true);
                        })
                    }}>
                        Swap!
                    </Button>
                </Col>
                <Col>
                    <Button style={{background: props.colors.support_color}} variant="contained" onClick={() => {
                        window.open('http://141.94.241.179:3010/');
                    }}>
                        {"Open Dashboard for " + e['name']}
                    </Button>
                </Col>
            </Row>
        )
    })

    return (
        <div >
            <Row style={{color: props.colors.text_color, marginTop: '0%', padding: '2%', fontWeight: '600', fontSize: '1.1em', borderBottom: '1px solid grey'}}>
                <Col>
                    Name
                </Col>
                <Col>
                    site_id
                </Col>
                <Col>
                    Swap to this client
                </Col>
                <Col>
                    Open Dashboard
                </Col>
            </Row>
            <div style={{maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden'}}>
                {map}
            </div>
            

        </div>
        
    )
}

export default Clients;