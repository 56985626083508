import { useState } from "react";
import axios from 'axios';
import {Row, Col} from 'react-bootstrap'

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

function Connection(props)
{
    let [data, setData] = useState(undefined);

    if (data === undefined)
    {
        let end = new Date();
        let start = new Date(new Date(end).setDate(end.getDate() - 7));
        axios(my_host + '/logs', { method: 'POST', data: { start: start, end: end }, withCredentials: true, useCredentials: true }).then(e => {
            console.log(e.data.response);
            setData(e.data.response);
        })
        return (<div>KONOSUBA</div>)
    }
    
    return (
        <div className="square">
            <Row style={{borderTop: '1px solid grey', padding: '1%', color: props.colors.text_color, fontWeight: '600'}}>
                <Col>
                    Email
                </Col>
                <Col>
                    Ip
                </Col>
                <Col>
                    Date
                </Col>
                <Col>
                    Error
                </Col>
            </Row>
            {data.map((e, i)=> {
                return (
                    <Row style={{borderTop: '1px solid grey', padding: '1%', color: props.colors.text_color, background: e.failed ? '#ff2d2d17' : '#38df380f '}}>
                        <Col>
                            {e.email == "" ? 'Empty' : e.email}
                        </Col>
                        <Col style={{borderLeft: '1px solid grey'}}>
                            {e.ip}
                        </Col>
                        <Col style={{borderLeft: '1px solid grey'}}>
                            {e.created_at.replace('T', ' ').replace('Z', ' ').substring(0, e.created_at.replace('T', ' ').replace('Z', ' ').length - 5)}
                        </Col>
                        <Col style={{borderLeft: '1px solid grey'}}>
                            {e.error}
                        </Col>
                    </Row>
                )
            })}
            
            
        </div>
    )
}

export default Connection;