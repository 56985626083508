import { Row, Col } from 'react-bootstrap';
import './style.css';
import MenuIcon from '@mui/icons-material/Menu';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EngineeringIcon from '@mui/icons-material/Engineering';
import StorageIcon from '@mui/icons-material/Storage';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BugReportIcon from '@mui/icons-material/BugReport';
import HttpsIcon from '@mui/icons-material/Https';
import { Link, Router} from "react-router-dom";

let list_pages = [
    ['Overview', <AssessmentIcon/>, '/Overview'],
    ['Dashboard', <DashboardIcon/>, '/Dashboard'],
    ['Api', <EngineeringIcon/>, 'Api'],
    ['Treatment', <EngineeringIcon/>, 'Treatment'],
    ['Services', <StorageIcon/>, '/Services'],
    ['Test', <BugReportIcon/>, '/Test'],
    ['Connections', <HttpsIcon/>, '/Connections'],
    ['Monitoring', <HttpsIcon/>, '/ELK'],
    
    
]


function Sidebar(props) {
    
  return (
    <div className='sidebar' style={{background: props.colors.sidebar, color: props.colors.text_color}}>
        <Row style={{padding: '10% 7% 7% 7%'}}>
            <Col style={{marginLeft: '2%'}} sm={2}>
                <MenuIcon/>
            </Col>
            <Col style={{marginTop: '1%'}}>
                DATADRIVEN-GESTION
            </Col>
        </Row>
        {list_pages.map((e, i) => {
            return (
                <Link style={{color: props.colors.text_color, textDecoration: 'none'}} to={e[2]}>
                    <Row style={{marginTop: i === 0 ?  '20%' : 'inherit', borderRight: props.selected === i ? '5px solid ' + props.colors.support_color : 'none'}} className={props.selected === i ? 'sidebarSelected': 'sidebarElem'} onClick={() => {
                        props.handleChange(i);

                    }}>
                        <Col style={{marginLeft: '2%', color: props.selected === i ? props.colors.support_color : props.colors.text_color}} sm={2}>
                            {e[1]}
                        </Col>
                        <Col>
                            {e[0]}
                        </Col>
                    </Row>
                </Link>
            )
        })}
        
    </div>
  );
}

export default Sidebar;
