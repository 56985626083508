import { Avatar, Paper, Select, MenuItem, FormControl, InputLabel, TextField, Tooltip } from "@mui/material"
import { Container, Row, Col } from "react-bootstrap"
import samsic from '../images/samsic.png';
import leroy from '../images/leroy.png';
import ga from '../images/ga.png';
import RangePicker from "react-range-picker"
import axios from "axios";
import { useEffect, useState } from "react";


export const data = {
    label: [],
    datasets: [
        {
            label: 'number of connection',
            fill: true,
            data: [],
            borderColor: 'rgb(14, 57, 58)',
            backgroundColor: 'rgba(14, 57, 58, 0.5)',
        },
    ],
};


var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

var config = (d1, d2) => {
    return {
        method: 'post',
        url: my_host + '/comp_kpi',
        withCredentials: true,
        useCredentials: true,
        data: {
            "start": d1,
            "end": d2
        },
        headers: {
            'Content-Type': 'application/json'
        }
    }
}

function Source() {

    const [data, setData] = useState(undefined);
    const [sort, setSort] = useState(undefined);
    const [filter, setFilter] = useState(undefined);

    let edate = new Date();
    let sdate = new Date();
    sdate.setDate(sdate.getDate() - 7);
    const [start, setStart] = useState(sdate)
    const [end, setEnd] = useState(edate)
    const [calendar, setCalendar] = useState(false);

    useEffect(() => {
        axios(config(start, end))
            .then(function (response) {
                setData(response.data);
                //console.log(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [end])

    if (!data) {
        return (
            <div>
                il est lent ce lait (ça met environ 2 minutes à charger)
            </div>
        )
    }


    return (
        <>
            <Container style={{ padding: '3%' }} fluid>
                <Row style={{ marginBottom: '2%' }}>
                    <Col>
                        <RangePicker
                            defaultValue={{ startDate: start, endDate: end }}
                            closeOnSelect={true}
                            onClose={() => {
                                console.log('OUIIIIIIIIIIIIII');
                                setCalendar(false)
                                setData(undefined);
                            }}
                            rangeTillEndOfDay={true}
                            onDateSelected={(a, b) => {
                                setCalendar(true);
                                let d1 = new Date(a)
                                let d2 = new Date(b)
                                console.log('date: ', a, b, calendar);
                                if (a)
                                    setStart(d1)
                                if (b)
                                    setEnd(d2)

                            }}
                        />

                    </Col>
                </Row>
                <Row style={{ padding: '1%' }}>
                    {Object.keys(data[0]).map(e => {
                        return (
                            <Col>
                                {e}
                            </Col>)
                    })}
                </Row>
                {data.map(e => {
                    return (
                        <Row style={{ padding: '1%', borderBottom: '1px solid grey' }}>
                            {Object.keys(e).map(t => {
                                return (
                                    <Col>
                                        {e[t]}
                                    </Col>
                                )
                            })}
                        </Row>
                    )
                })}

            </Container>
        </>
    )
}

export default Source