import {Row, Col, Button} from 'react-bootstrap';

let mapPageType = {
    '0': 'None',
    '1': 'Consultations',
    '5': 'Candidatures initiées',
    '15': 'Candidatures finalisées',
    '12': 'Entretiens',
    '13': 'Embauches'
}

const Global = (props) => {
    if (!props.data)
    {
        return <>Cringe Loading</>
    }
    if (props.val == 0)
    {
        props.setVal(Object.keys(props.data)[0]);
        return <></>
    }
    let button = Object.keys(props.data).map((e, i) => {
        return (
            <Col >
                <Button style={{backgroundColor: props.colors.sidebar, color: props.colors.text_color, border: props.colors.text_color}} onClick={() => props.setVal(e)}>
                    {e}
                </Button>
            </Col>
        )
    });

    let bandeau;
    if (props.data[props.val] && props.data[props.val].length > 0)
    {
        bandeau = Object.keys(props.data[props.val][0]).map(e => {
            console.log("val", e);
            return (
                <Col>
                    {e}
                </Col>
            )
        });
    } else {
        bandeau = <>{props.val} is empty (bitch)</>
    }
    
    let values  = props.data[props.val].map(e => {
        return (
            <Row>
                {Object.keys(e).map(it => {
                    return (
                        <Col>
                            {it === 'page_type' || it === 'kpi_type' ? mapPageType[e[it]] : e[it]}
                        </Col>
                    )
                })}
            </Row>
        )
    })

    return (
        <>
            <Row>
                {button}
            </Row>
            <Row style={{marginTop: '3%', marginBottom: '2%', fontWeight: '600', fontSize: '1.3em', borderBottom: '1px solid grey'}}>
                {bandeau}
            </Row>
            {values}    
        </>
    )
}

export default Global;