import './style.css'

import {Row, Col, Form, Button} from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import close from '../images/close.png';
import { useState } from 'react';

const ModifyApiModal = (props) => {
    const [name, setName] = useState(props.element.name);
    const [description, setDescription] = useState(props.element.description);
    const [args, setArgs] = useState(props.element.args_values);
    const [startTime, setStartTime] = useState(props.element.start_time);
    const [enabled, setEnabled] = useState(props.element.enabled);

    const handleCloseModal = () => {
        props.setModalOpen(false);
    }

    const handleChangeName = (e) => {
        setName(e.target.value);
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    }

    const handleChangeArgs = (e) => {
        setArgs(e.target.value);
    }

    const handleStartTime = (e) => {
       setStartTime(e);
    }

    const handleChangeEnabled = (e) => {
        setEnabled(!enabled);
    }

    const getMandatoryArgs = (args) => {
        let rep_args = args.replace(', startDate', '').replace(', endDate', '').replace(', nbDays', '').replace('startDate', '');
        return rep_args ? rep_args : 'None'
    }

    const handleUpdate = () => {
        const scriptUpdated = {
            name,
            description,
            start_time: startTime,
            enabled,
            args_values: args
        };
        props.updateScript(scriptUpdated);
        props.setModalOpen(false);
    }

    return (
        <div>
            <div className='modal-body'>
                <Row className='table-row'>
                    <Col>
                        <h3>Modify execution {props.element.name}</h3>
                    </Col>
                    <Col style={{textAlign: 'right', marginBottom: '5px'}}>
                        <img src={close} onClick={handleCloseModal} style={{width: '30px', marginLeft: 'auto', marginRight: '20px', marginTop: '20px', cursor: 'pointer'}}/>
                    </Col>
                </Row>
                <Form>
                    <Row className='table-row'>
                        <Col>
                            <Form.Label>Name</Form.Label>
                            <Form.Control value={name} onChange={handleChangeName} type="text" placeholder="Name" defaultValue={props.element.name}></Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>Description</Form.Label>
                            <Form.Control value={description} onChange={handleChangeDescription}  type="text" placeholder="Description" defaultValue={props.element.description}></Form.Control>
                        </Col>
                    </Row>
                    <Row className='table-row'>
                        <Col>
                            <Form.Label>Arguments</Form.Label>
                            <div style={{paddingBottom: '20px'}}>
                                <span style={{color: 'green'}}>All arguments: <b>{props.element.args_labels ? props.element.args_labels : 'None'}</b></span><br />
                                <span style={{color: 'red'}}>Mandatory arguments: <b>{props.element.args_labels ? getMandatoryArgs(props.element.args_labels) : 'None'}</b></span>
                            </div>
                            <Form.Control value={args} onChange={handleChangeArgs} type="text" placeholder="Arguments separate with commas."></Form.Control>
                        </Col>
                    </Row>
                    <Row className='table-row'>
                        <Col>
                            <Form.Label>Start time</Form.Label><br />
                            <TimePicker value={startTime} onChange={handleStartTime} className="timepicker" />
                        </Col>
                        <Col>
                            <Form.Label>Enabled</Form.Label>
                            <Form.Check type="checkbox" checked={enabled} onChange={handleChangeEnabled}></Form.Check>
                        </Col>
                    </Row>
                    <Row className='table-row'>
                        <Col></Col>
                        <Col style={{ width: '100%', textAlign: 'right'}}>
                            <Button variant='success' onClick={handleUpdate}>Update</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default ModifyApiModal;