import './style.css'
import {Row, Col, Form, Button} from 'react-bootstrap';
import close from '../images/close.png';

import { useState } from 'react';

const ExecuteApiModal = (props) => {
    const [args, setArgs] = useState();

    const handleCloseModal = () => {
        props.setModalOpen(false);
    }

    const handleChangeArgs = (e) => {
        setArgs(e.target.value);
    }

    const handleExecute = () => {
        console.log('Execute', args);
        props.executeScript(args);
        props.setModalOpen(false);
    }

    const getMandatoryArgs = (args) => {
        let rep_args = args.replace(', startDate', '').replace(', endDate', '').replace(', nbDays', '').replace('startDate', '');
        return rep_args ? rep_args : 'None'
    }

    return (
        <div>
            <div className='modal-body'>
                <Row className='table-row'>
                    <Col>
                        <h3>Execute {props.element.name}</h3>
                        <h6 style={{color: 'red'}}>Please fill arguments before to execute the api.</h6>
                    </Col>
                    <Col style={{textAlign: 'right', marginBottom: '5px'}}>
                        <img src={close} onClick={handleCloseModal} style={{width: '30px', marginLeft: 'auto', marginRight: '20px', marginTop: '20px', cursor: 'pointer'}}/>
                    </Col>
                </Row>
                <Form>
                    <Row className="table-row">
                        <div style={{paddingBottom: '20px'}}>
                            <span style={{color: 'green'}}>All arguments: <b>{props.element.args_labels ? props.element.args_labels : 'None'}</b></span><br />
                            <span style={{color: 'red'}}>Mandatory arguments: <b>{props.element.args_labels ? getMandatoryArgs(props.element.args_labels) : 'None'}</b></span>
                        </div>
                    </Row>
                    <Row className='table-row'>
                        <Col>
                            <Form.Label>Arguments</Form.Label>
                            <Form.Control value={args} onChange={handleChangeArgs} type="text" placeholder="Arguments separate with commas."></Form.Control>
                        </Col>
                    </Row>
                    <Row className='table-row'>
                        <Col></Col>
                        <Col style={{ width: '100%', textAlign: 'right'}}>
                            <Button variant='success' onClick={handleExecute}>Execute</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default ExecuteApiModal;