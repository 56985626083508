import { useEffect, useState } from "react";
import axios from 'axios';
import { Paper } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { Chart, registerables } from 'chart.js'
import { Bar, Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );
  



export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  

var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

function get_data(data)
{

    let lab = [];
    let map = [];

    data.map(e => {
        let ts = e.timestamp.substring(0, 16).replace('T', ' ');
        if (!lab.includes(ts))
        {
            lab.push(ts)
            map.push(0);
        }
        //could have optimize but let be sure
        let index = lab.indexOf(ts);
        map[index] += 1;
        
    })
    lab = lab.splice(0, 50);
    map = map.splice(0, 50)
    
    let d = {
        labels: lab.reverse(),
        datasets: [
          {
            label: 'Logs Volume',
            data: map.reverse(),
            backgroundColor: 'rgb(221, 71, 212)',
            fill: "origin"
          }
        ],
      };
    return d
}

function get_data_volume(data)
{

    let lab = [];
    let map = [];
    if (!data)
    {
        return {
            labels: lab.reverse(),
            datasets: [
              {
                label: 'Logs Volume',
                data: map.reverse(),
                backgroundColor: 'yellow',
                fill: "origin"
              }
            ],
        };
    }
    lab = Object.keys(data);
    map = Object.values(data).map(e => {return e / 1024});
    let d = {
        labels: lab,
        datasets: [
          {
            label: 'Data Volume in Kb',
            data: map,
            backgroundColor: 'yellow',
            fill: "origin"
          }
        ],
    };
    return d
}

function log_color(stdtype, message)
{
    if (message.toLowerCase().includes('success'))
    {
        return 'green'
    }
    if (stdtype == 'stderr')
    {
        return 'red'
    }
    if (stdtype == 'stdout')
    {
        return 'blue'
    }
    return 'white'
}

function Monitoring(props)
{
    const [response, setResponse] = useState(undefined)
    const [volume, setVolume] = useState(undefined)
    useEffect(e => {
        const interval = setInterval(() => {
            axios(my_host + '/elk_logs', { method: 'GET', data: {}, withCredentials: true, useCredentials: true }).then(e => {
                setResponse(e.data);
            });
        }, 5000);
        return () => {clearInterval(interval)}
    })

    useEffect(e => {
        const interval = setInterval(() => {
            axios(my_host + '/volume', { method: 'GET', data: {}, withCredentials: true, useCredentials: true }).then(e => {
                console.log(e.data);
                setVolume(e.data.Result);
            });
        }, 5000);

        return () => {clearInterval(interval)}
    })

    if (!response)
    {
        return <div>LOADING...</div>
    }

    return (
        <div>
            <Row style={{minHeight: '35vh', maxHeight: '35vh', overflowY: 'scroll', overflowX: 'hidden', padding: '0 2% 0 2%'}}>
            <Paper className='papier' style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '1%'}} elevation={1}>
                <Col>
                    <div>
                        {response.map(e => {
                            return (
                                <Row style={{padding: '0 1% 0 1%'}}>
                                    <Col style={{color: 'white'}} xs={2}>
                                        {e.timestamp.replace('T', ' ').replace('Z', ' ').substring(0, 19)}
                                    </Col>
                                    <Col style={{color: 'wheat'}} xs={2}>
                                        {e.container_name}
                                    </Col>
                                    <Col style={{color: log_color(e.stream_source, e.message)}}>
                                        {e.message}
                                    </Col>
                                </Row>
                            )
                        })}
                    </div>
                </Col>
                </Paper>
            </Row>
            <Row style={{minHeight: '35vh', maxHeight: '50%', marginTop: '3%', padding: '0 1% 0 1%'}}>
                <Col>
                    <Paper className='papier' style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '5%'}} elevation={1}>
                        <Bar options={options} data={get_data(response)} />
                    </Paper>
                </Col>
                <Col>
                    <Paper className='papier' style={{background: props.colors.sidebar, color: props.colors.text_color, padding: '5%'}} elevation={1}>
                        <Bar options={options} data={get_data_volume(volume)} />
                    </Paper>
                </Col>
            </Row>
        </div>
    )
}

export default Monitoring;