import {Row, Col} from 'react-bootstrap'

const Abonnement = (props) => {
    let map = props.abo.map(e => {
        return ( 
            <Row style={{color: props.colors.text_color, padding: '2%', borderBottom: '1px solid grey'}}>
                <Col>
                    {e['name']}
                </Col>
                <Col>
                    {e['debut'].substring(0, 10)}
                </Col>
                <Col>
                    {e['fin'].substring(0, 10)}
                </Col>
                <Col>
                    {e['type_abonnement']}
                </Col>
                <Col>
                    {e['quantite']}
                </Col>
                <Col>
                    {e['montant'] + '€'}
                </Col>
            </Row>
        )
    })
    return (<div>
        <Row style={{color: props.colors.text_color, marginTop: '5%', padding: '2%', fontWeight: '600', fontSize: '1.1em', borderBottom: '1px solid grey'}}>
                <Col>
                    Support
                </Col>
                <Col>
                    Debut de contrat
                </Col>
                <Col>
                    Fin de contrat
                </Col>
                <Col>
                    Type d'abonnement
                </Col>
                <Col>
                    Quantite
                </Col>
                <Col>
                    Montant
                </Col>
            </Row>
        {map}
    </div>);
}

export default Abonnement;