import { Avatar, Button, Paper } from "@mui/material"
import { Container, Row, Col } from "react-bootstrap"
import axios from "axios";
import rick from '../images/rick-and.gif';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState } from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    scales: {
        x: {
            grid: {
                color: 'white',
                borderColor: '#0E393A',
            }
        },
        y: {
            ticks: {
                stepSize: 25
            },
            grid: {
                color: '#0E393A',
                borderColor: '0E393A',
            }
        },
    },
    plugins: {
        legend: {
            display: true
        },
        title: {
            display: true,
            text: '',
        }
    },

};


const generateData = (data) => {
    //could have been a loop I know
    //console.log(data);
    let completed = data.filter(e => { return e.kpi_type === 15 });
    completed.sort((a, b) => { return b.site_id - a.site_id });
    let entretiens = data.filter(e => { return e.kpi_type === 12 });
    entretiens.sort((a, b) => { return b.site_id - a.site_id });
    let hired = data.filter(e => { return e.kpi_type === 13 });
    hired.sort((a, b) => { return b.site_id - a.site_id });
    let consult = data.filter(e => { return e.kpi_type === 1 });
    consult.sort((a, b) => { return b.site_id - a.site_id });

    console.log(completed.map(e => {return e.site_id}));
    console.log(completed);
    console.log(entretiens.map(e => {return e.site_id}));
    console.log(hired.map(e => {return e.site_id}));
    console.log(consult.map(e => {return e.site_id}));

    //console.log('6666', hired);

    //if there is no company name it crashes, just saying

    return {
        labels: completed.map(e => e.company_name.company_name),
        datasets: [
            {
                label: 'completed',
                fill: true,
                data: completed.map(e => e.percent),
                borderColor: 'rgb(14, 57, 58)',
                backgroundColor: '#0E393A',
            },
            {
                label: 'Entretiens',
                fill: true,
                data: entretiens.map(e => e.percent),
                borderColor: 'rgb(14, 57, 58)',
                backgroundColor: '#EF8351',
            },
            {
                label: 'Embauche',
                fill: true,
                data: hired.map(e => e.percent),
                borderColor: 'rgb(14, 57, 58)',
                backgroundColor: '#ECCC50',
            },
            {
                label: 'Consultation',
                fill: true,
                data: consult.map(e => e.percent),
                borderColor: 'rgb(14, 57, 58)',
                backgroundColor: '#00887E',
            }
        ],
    }
};


function carte(label, nb, date, negativ, comment) {
    return (
        <Paper style={{ borderRadius: '10px', padding: '4%', background: negativ ? '#0E393A' : '#f3f3f3', color: negativ ? 'white' : 'black' }} elevation={0}>
            <Row>
                <Col sm={8} style={{ fontSize: '1.0em', color: negativ ? 'white' : 'black', fontWeight: '600' }}>
                    {label}
                </Col>
                <Col style={{ fontSize: '0.7em', color: 'grey', fontWeight: '300', textAlign: 'right' }}>
                    {new Date().toDateString()}
                </Col>
            </Row>
            <Row style={{ marginTop: '3%' }}>
                <Col style={{ fontSize: '1.9em', fontWeight: '600', textAlign: 'center' }}>
                    {(nb * 100).toFixed(2) + '%'}
                </Col>
            </Row>
            <Row>
                <Col style={{ fontSize: '0.8em', fontWeight: '300', color: 'grey' }}>
                    {comment}
                </Col>
            </Row>
        </Paper>
    )
}


var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";

var config = {
    method: 'get',
    url: my_host + '/percent',
    withCredentials: true,
    useCredentials: true,
    data: {},
    headers: {
        'Content-Type': 'application/json'
    }
}

function Principale() {

    const [dataOver, setDataOver] = useState(undefined);
    const [current, setCurrent] = useState('prod')

    useEffect(() => {
        axios(config)
            .then(function (response) {
                setDataOver(response.data.response)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])


    if (!dataOver) {
        return (
            <img src={rick} style={{position: 'absolute', top: '20vh', left: '55vh', zIndex: '10'}}/>
        )
    }
    let data = undefined;
    if (current === 'prod') {
        data = dataOver.prod;
    } else {
        data = dataOver.dev;
    }

    return (
        <>
            <Container style={{ padding: '3%' }} fluid>
                <Row>
                    <Col sm={3}>
                        {carte('Accuracy Général', (data.reduce((a, b) => a + b.percent, 0)) / data.length, new Date(), true, 'suspicieux mais is ok')}
                    </Col>
                    <Col>
                        {carte('Accuracy Completed', (data.filter(e => { return e.kpi_type == 15 }).reduce((a, b) => a + b.percent, 0)) / data.filter(e => { return e.kpi_type == 15 }).length, new Date(), false, 'suspicieux mais is ok')}
                    </Col>
                    <Col>
                        {carte('Accuracy Entretiens', (data.filter(e => { return e.kpi_type == 12 }).reduce((a, b) => a + b.percent, 0)) / data.filter(e => { return e.kpi_type == 12 }).length, new Date(), false, 'suspicieux mais is ok')}
                    </Col>
                    <Col>
                        {carte('Accuracy Embauches', (data.filter(e => { return e.kpi_type == 13 }).reduce((a, b) => a + b.percent, 0)) / data.filter(e => { return e.kpi_type == 13 }).length, new Date(), false, 'suspicieux mais is ok')}
                    </Col>
                    <Col>
                        {carte('Accuracy Consultation', (data.filter(e => { return e.kpi_type == 1 }).reduce((a, b) => a + b.percent, 0)) / data.filter(e => { return e.kpi_type == 1 }).length, new Date(), false, 'suspicieux mais is ok')}
                    </Col>
                </Row>
                <Row style={{ marginTop: '2%' }}>
                    <Col>
                        <Paper style={{ border: '1px solid #F5F5F5', padding: '3%' }} elevation={0}>
                            <Button variant='contained' style={{ background: '#0E393A' }} onClick={() => { setCurrent(current === 'prod' ? 'dev' : 'prod') }}>
                                Go to {current === 'prod' ? 'OnBoarding' : 'prod'}
                            </Button>
                            <Bar options={options} data={generateData(data)} width={"100%"} height={"40%"} />
                        </Paper>

                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Principale 