import {Row, Col, Button, Form} from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import ModifyApiModal from '../Component/ModifyApiModal';
import ExecuteApiModal from '../Component/ExecuteApiModal';
import axios from 'axios';
import Poubelle from '../images/trash.svg';
import {Alert} from 'react-bootstrap';
import './style.css';

let my_host = window.location.href.includes('localhost') ? 'http://localhost:3042' : 'https://retraitement.data-driven.fr'

const Treatment = (props) => {
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [updateMessage, setUpdateMessage] = useState('');
    const [updateOpen, setUpdateOpen] = useState(false);
    const [argOpen, setArgOpen] = useState(false);
    const [apiFiles, setApiFiles] = useState([]);
    const [filterFiles, setFilterFiles] = useState({});
    const [modalToUpdate, setModalToUpdate] = useState({name: '', description: '', start_time: '', enabled: null, args_values: ''});

    const searchFilter = useRef();

    useEffect(() => {
        checkTreatmentInfo();
    }, []);

    useEffect(() => {
        if(reload === true) {
            fetchData();
            setLoading(false);
            setReload(false);
        }
    }, [reload]);

    const checkTreatmentInfo = () => {
        fetchData();
        clearTimeout();
        setTimeout(() => {checkTreatmentInfo()}, 30000)
    }

    const fetchData = () => {
        axios(my_host + '/treatment_info', { method: 'GET' })
            .then(response => {
                setApiFiles(response.data.Response);

                let keysFilterFiles = Object.keys(filterFiles);

                if(keysFilterFiles.length > 0) {
                    let filterFilesCopy = filterFiles;

                    keysFilterFiles.map(key => {
                        filterFilesCopy[key] = response.data.Response[key];
                    })
                    console.log(filterFilesCopy);
                    setFilterFiles(filterFilesCopy);
                }

                setLoading(false);

                if(updateMessage === 'Updating...') {
                    setUpdateMessage('Updated');
                    setTimeout(() => {setUpdateMessage('')}, 5000);
                }
            })
            .catch(e => {
                console.log("error", e);
            })
    } 

    const handleSearch = (e) => {
        e.preventDefault();

        let searchValue = searchFilter.current.value;

        let filtered = Object.keys(apiFiles).filter(key => apiFiles[key].name.toLowerCase().includes(searchValue));
        let filteredFiles = filtered.map(key => {
            return {
                [apiFiles[key].name]: apiFiles[key]
            }
        })

        setFilterFiles(filteredFiles[0]);
        setReload(true);
    }

    const handleResetFilter = () => {
        setFilterFiles({});
        searchFilter.current.value = '';
        setReload(true);
    }

    const handleOpenModal = (type, element) => {
        if(type === 'update') {
            setUpdateOpen(true);
            setModalToUpdate(element);
        } else if(type === 'arg') {
            setArgOpen(true);
            setModalToUpdate(element);
        }
    }

    const updateApiState = async (field, value, scriptName) => {
        await axios.post(my_host + '/change_treatment', {field, value, treatment: scriptName})
        .catch(e => {
            console.log('update script error', e);
        })
    }

    const handleUpdateScript = async (script) => {
        try {
            if(modalToUpdate.name && modalToUpdate.start_time && modalToUpdate.enabled !== null) {
                setUpdateMessage('Updating...');
                for (const key of Object.keys(script)) {
                    await updateApiState(key, script[key], script.name);
                }
                setReload(true);
            }
        } catch(e) {
            console.log('ERROR', e);
        }
    }

    const handleExecuteScript = async(args) => {
        args = args ? args.replace(/ /g, '') : null;
        args = args ? args.split(',') : null;
        await axios.post(my_host + '/launch_treatment', {treatment: modalToUpdate.name, args: args});
        await updateApiState('in_execution', true, modalToUpdate.name);
        setReload(true);
        setArgOpen(false);
    }

    const stopProcess = async(treatment) => {
        await axios.post(my_host + '/stop_treatment', {treatment: treatment.name});
    }

    if(loading) {
        return (
            <p>Loading...</p>
        )
    }

    const files = Object.keys(filterFiles).length > 0 ? filterFiles : apiFiles;

    return (
        <div className='square'>
            <Modal
                className='api-modal'
                isOpen={updateOpen}
            >
                <ModifyApiModal setModalOpen={setUpdateOpen} element={modalToUpdate} updateScript={handleUpdateScript} />
            </Modal>
            <Modal
                className='api-modal'
                isOpen={argOpen}
            >
                <ExecuteApiModal setModalOpen={setArgOpen} element={modalToUpdate} executeScript={handleExecuteScript}/>
            </Modal>
            <div className="filter-header">
                <Row>
                    <Col>
                        {updateMessage && <Alert variant={updateMessage === 'Updating...' ? 'danger' : 'success'}>{updateMessage}</Alert>}
                    </Col>
                    <Col style={{display: 'flex', flexDirection: 'row', textAlign: 'right'}}>
                        <Form onSubmit={handleSearch} style={{width: '80%', margin: 0}}>
                            <Form.Control type="text" placeholder="Search..." ref={searchFilter} />
                        </Form>
                        <div>
                            <img onClick={handleResetFilter} src={Poubelle} style={{display: 'block', width: 30, marginLeft: '10px', marginTop: '5px', cursor: 'pointer'}} />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='api-table'>
                <Row className="sticky" style={{borderTop: '1px solid grey', padding: '1%', color: props.colors.text_color, fontWeight: '600', width: '100%', textAlign: 'center'}}>
                    <Col xs={1}>Enabled</Col>
                    <Col>Name</Col>
                    <Col>Description</Col>
                    <Col>Start time</Col>
                    <Col>Last execution</Col>
                    <Col xs={1}>Executing</Col>
                    <Col xs={3}>Actions</Col>
                </Row>
                {!loading ?
                    <>
                    {Object.keys(files).map((key => {
                            let last_exec = new Date(files[key].last_execution);
                            return (
                                <Row style={{borderTop: '1px solid grey', padding: '1%', color: props.colors.text_color, width: '100%', textAlign: 'center'}}>
                                    <Col xs={1}>
                                        {files[key].enabled === true ?
                                            <span className='dot green'></span>
                                        : <span className='dot red'></span>}
                                    </Col>
                                    <Col>
                                        {files[key].name}
                                    </Col>
                                    <Col>
                                        {files[key].description}
                                    </Col>
                                    <Col>
                                        {files[key].start_time}
                                    </Col>
                                    <Col>
                                        {new Date(last_exec.setHours(last_exec.getHours() + 2)).toISOString().replace('T', ' ').replace('Z', '')}
                                    </Col>
                                    <Col xs={1}>
                                        {files[key].in_execution === true ?
                                            <span className='dot green'></span>
                                        : <span className='dot red'></span>}
                                    </Col>
                                    <Col xs={3}>
                                        <Button variant="info" style={{marginRight: '10px'}} onClick={() => handleOpenModal('arg', apiFiles[key])}>Execute</Button>
                                        <Button variant="danger" style={{marginLeft: '10px'}} onClick={() => stopProcess(apiFiles[key])}>Stop</Button>
                                        <Button variant="warning" style={{marginLeft: '10px'}} onClick={() => handleOpenModal('update', apiFiles[key])}>Modify</Button>
                                    </Col>
                                </Row>
                            )
                        }))}
                    </>
                : <p>Loading...</p>
                }
            </div>
        </div>
    )
}

export default Treatment;