import { Avatar, Paper, Tooltip } from "@mui/material"
import { Container, Row, Col } from "react-bootstrap"
import rick from '../images/rick-and.gif';
import axios from "axios";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState } from "react";
import RangePicker from "react-range-picker"
import { DateRangePicker } from 'react-date-range';
import { da } from "date-fns/locale";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend
);

const options = {
    responsive: true,
    scales: {
        x: {
            grid: {
                color: 'white',
                borderColor: 'white',
            }
        },
        y: {
            ticks: {
                stepSize: 30
            },
            grid: {
                color: '#f3f3f3',
                borderColor: 'white',
            }
        },
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: '',
        }
    },

};

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


const generateData = (data) => {

    return {
        labels: data.map(e => { return e.date }),
        datasets: [
            {
                label: 'number of connection',
                fill: true,
                data: data.map(e => { return parseInt(e.count) }),
                borderColor: '#ECCC50',
                backgroundColor: '#ECCC503A',
            },
        ],
    }
};


function carte(label, nb, date, negativ, comment) {
    return (
        <Paper style={{ borderRadius: '10px', padding: '4%', background: negativ ? '#0E393A' : '#f3f3f3', color: negativ ? 'white' : 'black' }} elevation={0}>
            <Row>
                <Col sm={8} style={{ fontSize: '1.0em', color: negativ ? 'white' : 'black', fontWeight: '600' }}>
                    {label}
                </Col>
                <Col style={{ fontSize: '0.7em', color: 'grey', fontWeight: '300', textAlign: 'right' }}>
                    {new Date().toDateString()}
                </Col>
            </Row>
            <Row style={{ marginTop: '3%' }}>
                <Col style={{ fontSize: '1.9em', fontWeight: '600', textAlign: 'center' }}>
                    {nb}
                </Col>
            </Row>
            <Row>
                <Col style={{ fontSize: '0.8em', fontWeight: '300', color: 'grey' }}>
                    {comment}
                </Col>
            </Row>
        </Paper>
    )
}

function stageStyle(stage, text) {
    if (stage == 'dev') {
        return (
            <Col style={{ background: 'rgba(14, 57, 58, 0.5)', color: 'rgba(14, 57, 58)' }}>
                {text}
            </Col>
        )
    }
    if (stage == 'onBoarding') {
        return (
            <Col style={{ background: 'rgba(239, 131, 81, 0.5)', color: 'rgba(14, 57, 58)' }}>
                {text}
            </Col>
        )
    }
    if (stage == 'prod') {
        return (
            <Col style={{ background: 'rgba(14, 57, 58, 0.5)', color: 'rgb(14, 57, 58)' }}>
                {text}
            </Col>
        )
    }
    return (
        <Col style={{ background: 'rgba(14, 57, 58, 0.5)', color: 'rgb(14, 57, 58)' }}>
            {text}
        </Col>
    )

}

function progbar(percent, color, text, img) {
    return (
        <div style={{ marginTop: '5%' }}>
            <Row>
                <Col style={{ marginTop: '-1.5%' }} sm={2}>
                    <Avatar alt='truc' src={img} />
                </Col>
                <Col style={{ fontWeight: '600' }} sm={7}>
                    {text}
                </Col>
                <Col style={{ fontSize: '0.9em' }}>
                    {(percent * 100).toFixed(2) + ' %'}
                </Col>
            </Row>
            <Row style={{ marginTop: '4%' }}>
                <Col>
                    <LinearProgress style={{ borderRadius: '10px' }} size={"2em"} variant="determinate" value={percent * 100}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: color
                            }
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}
var my_host = process.env.REACT_APP_DASHBOARD_HOST

if (!my_host)
    my_host = "http://localhost:3004";



function Principale() {

    let edate = new Date();
    let sdate = new Date();
    sdate.setDate(sdate.getDate() - 7);
    const [dataOver, setDataOver] = useState(undefined);
    const [start, setStart] = useState(sdate)
    const [end, setEnd] = useState(edate)
    const [calendar, setCalendar] = useState(false);

    console.log('Calendar: ', calendar );

    useEffect(() => {
        var config = {
            method: 'get',
            url: my_host + '/overview',
            withCredentials: true,
            useCredentials: true,
            params: { start: start.toISOString().substring(0, 10), end: end.toISOString().substring(0, 10) },
            data: {},
            headers: {
                'Content-Type': 'application/json'
            }
        }
        console.log('Till here');
        if (!calendar) {
            console.log('AXIOS');
            
            axios(config)
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    setDataOver(response.data.response)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }, [calendar])



    if (!dataOver) {
        return (
            <img src={rick} style={{ position: 'absolute', top: '20vh', left: '55vh', zIndex: '10' }} />
        )
    }


    let grouped = groupBy(dataOver.bugs.bugs, 'name');
    console.log(grouped);
    let sorted = Object.keys(grouped).sort((a, b) => { return grouped[b].length - grouped[a].length });

    let maxGroupped = Object.keys(grouped).reduce((a, b) => { return a + grouped[b].length }, 0)
    console.log('--->', maxGroupped);


    return (
        <>
            <Container style={{ padding: '3%' }} fluid>
                <Row style={{ marginBottom: '2%' }}>
                    <Col>
                        <RangePicker
                            defaultValue={{startDate: start, endDate: end}}
                            closeOnSelect={true}
                            onClose={() => {
                                console.log('OUIIIIIIIIIIIIII');
                                setCalendar(false)
                                setDataOver(undefined);
                            }}
                            rangeTillEndOfDay={true}
                            onDateSelected={(a, b) => {
                                setCalendar(true);
                                let d1 = new Date(a)
                                let d2 = new Date(b)
                                console.log('date: ', a, b, calendar);
                                if (a)
                                    setStart(d1)
                                if (b)    
                                    setEnd(d2)
                                
                            }}
                        />

                    </Col>
                </Row>
                <Row>
                    <Tooltip title={JSON.stringify(Object.keys(dataOver.anomalie).filter(e => { return dataOver.anomalie[e].zero.length > 0 }))}>
                        <Col>
                            {carte('Dash en anomalie (zero)', Object.keys(dataOver.anomalie).filter(e => { return dataOver.anomalie[e].zero.length > 0 }).length, new Date(), true, 'suspicieux mais is ok')}
                        </Col>
                    </Tooltip>

                    <Tooltip title={JSON.stringify(Object.keys(dataOver.anomalie).filter(e => { return dataOver.anomalie[e].cost.length > 0 }))}>
                        <Col>
                            {carte('Dash en anomalie (coût)', Object.keys(dataOver.anomalie).filter(e => { return dataOver.anomalie[e].cost.length > 0 }).length, new Date(), false, 'presque parfait')}
                        </Col>
                    </Tooltip>

                    <Tooltip title={JSON.stringify(Object.keys(dataOver.anomalie).filter(e => { return dataOver.anomalie[e].order.length > 0 }))}>
                        <Col>
                            {carte('Dash en anomalie (Order)', Object.keys(dataOver.anomalie).filter(e => { return dataOver.anomalie[e].order.length > 0 }).length, new Date(), false, 'Appelez un dev mdrrr')}
                        </Col>
                    </Tooltip>

                    <Col >
                        {carte('nombre de connections', dataOver.connection.connection_by_month[dataOver.connection.connection_by_month.length - 1].count, new Date(), false, 'ça fait beaucoup la non ?')}
                    </Col>
                    <Col >
                        {carte('nombre de clients monitorés', dataOver.nb_client, new Date(), true, 'ça fait beaucoup la non ?')}
                    </Col>
                </Row>
                <Row style={{ marginTop: '2%' }}>
                    <Col sm={8}>
                        <Paper style={{ border: '1px solid #F5F5F5', padding: '3%' }} elevation={0}>
                            <Row>
                                <Col style={{ fontWeight: '600', fontSize: '1.2em' }}>
                                    Nombre de connections
                                </Col>
                            </Row>
                            <Line options={options} data={generateData(dataOver.connection.connection_by_month)} width={"100%"} height={"30%"} />
                        </Paper>

                    </Col>
                    <Col>
                        <Paper style={{ border: '1px solid #F5F5F5', padding: '5%' }} elevation={0}>
                            <Row style={{ marginBottom: '7%' }}>
                                <Col style={{ fontWeight: '600', fontSize: '1.2em' }}>
                                    Client avec le plus d'erreur
                                </Col>
                            </Row>
                            <Row style={{ maxHeight: '27.3vh', overflowY: 'scroll' }}>
                                <Col>
                                    {sorted.map(e => {
                                        return progbar(grouped[e].length / maxGroupped, '#0E393A', e, null)
                                    })}
                                </Col>
                            </Row>
                        </Paper>
                    </Col>
                </Row>
                <Row style={{ marginTop: '2%' }}>
                    <Col >
                        <Paper style={{ border: '1px solid #F5F5F5', padding: '2%' }} elevation={0}>
                            <Row>
                                <Col style={{ fontWeight: '600' }}>
                                    Connexion récente
                                </Col>
                            </Row>
                            <Row style={{ color: 'grey', padding: '1%', fontWeight: '300', marginTop: '1%', borderBottom: '1px solid #e9e9e9' }}>
                                <Col>
                                    id
                                </Col>
                                <Col sm={4}>
                                    email
                                </Col>
                                <Col>
                                    Date
                                </Col>
                                <Col>
                                    client
                                </Col>
                                <Col>
                                    status
                                </Col>
                            </Row>
                            {dataOver.connection.connection_generic.slice(0, 5).map(e => {
                                return (
                                    <Row style={{ padding: '1%', fontWeight: '600', textAlign: 'left', fontSize: '0.7em' }}>
                                        <Col>
                                            {e.id}
                                        </Col>
                                        <Col sm={4}>
                                            {e.email}
                                        </Col>
                                        <Col>
                                            {e.created_at.substring(0, 10)}
                                        </Col>
                                        <Col>
                                            {'to_add'}
                                        </Col>
                                        <Col>
                                            {'Status not defined'}
                                        </Col>
                                        {/*stageStyle(e.status, e.status)*/}
                                    </Row>
                                )
                            })}
                        </Paper>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Principale 